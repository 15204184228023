import React, { ElementType, ReactElement, ReactNode } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { Svg } from 'ui/Svg'
import ExternalSvgIcon, { ExternalIcon, IconType } from 'components/Dashboard/ExternalSvgIcon'

export interface IconButtonContentProps {
  children: ReactNode
  startIcon?: ElementType
  startIconFill?: string
  startExternalIcon?: ExternalIcon
  endIcon?: ElementType
  dataTestId?: string
  fixedBackIconSize?: boolean
  sxFrontIcon?: ThemeUIStyleObject
  sxEndIcon?: ThemeUIStyleObject
  sx?: ThemeUIStyleObject
  className?: string
  svgFill?: string
}

/** This component can be used as `children` for MenuItem if there's start and/or end icon used. */
export default function IconButtonContent({
  children,
  startIcon,
  startIconFill,
  startExternalIcon,
  endIcon,
  dataTestId,
  fixedBackIconSize,
  sxFrontIcon,
  sxEndIcon,
  sx,
  className,
  svgFill,
}: IconButtonContentProps): ReactElement {
  return (
    <Flex
      data-testid={dataTestId}
      sx={{
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        ...sx,
      }}
      className={className}
    >
      {(startIcon || startExternalIcon) && (
        <Flex
          sx={{
            ...sxFrontIcon,
            alignItems: 'center',
            flexGrow: 0,
            flexShrink: 0,
          }}
        >
          {startExternalIcon && (
            <ExternalSvgIcon
              icon={startExternalIcon.icon}
              type={startExternalIcon.type}
              fill={
                svgFill ?? (startExternalIcon.type === IconType.LOCATIONS ? undefined : 'white')
              }
              sx={{
                width: '2.4rem',
                height: '2.4rem',
                mr: '0.8rem',
              }}
            />
          )}
          {startIcon && (
            <Svg
              fill={startIconFill || 'aliceBlue'}
              svg={startIcon}
              sx={{
                width: '2.4rem',
                height: '2.4rem',
                mr: '0.8rem',
              }}
            />
          )}
        </Flex>
      )}
      <Flex sx={{ flexGrow: 1, whiteSpace: 'nowrap' }}>{children}</Flex>
      {endIcon && (
        <Flex
          sx={{
            width: fixedBackIconSize ? '2.4rem' : 'inherit',
            alignItems: 'center',
            flexGrow: 0,
            flexShrink: 0,
            ml: '1.6rem',
            ...sxEndIcon,
          }}
        >
          <Svg svg={endIcon} />
        </Flex>
      )}
    </Flex>
  )
}
