import { EnabledStatus } from '../rules'
import { ApiResponse } from '../http'
import { SerializedError } from '@reduxjs/toolkit'
import { NukeAnalyticsType, SSOProvider } from 'store/api/user/user.interface'

type ItemCount = { count: number }
export type ItemCountWithMax = ItemCount & {
  /**
   * For users. The max value is the total number of connected clients and should be used for pricing calculations.
   */
  max: number
}
export type ItemCountWithMaxAndPrice = ItemCountWithMax & { price: number }

export type SsoSettingsInfo = {
  okta_domain?: string
  okta_client_id?: string
  okta_client_secret?: string
  sso_email_domains?: string //this is an array of strings separated by commas
  sso_provider?: SSOProvider
}

export type Organization = SsoSettingsInfo & {
  PK: string
  name: string
  status: EnabledStatus
  status_printed: string
  billing_method?: number
  billing_method_printed?: string
  contact_name?: string
  contact_email: string
  address?: string | null
  website?: string
  date: string
  twofa_req: EnabledStatus
  max_legacy_resolvers: number
  max_profiles: number
  max_sub_orgs: number
  max_devices: number
  stats_endpoint?: string
  [NukeAnalyticsType.ORG]?: EnabledStatus
  members: ItemCount
  profiles: ItemCountWithMax
  users: ItemCountWithMaxAndPrice
  sub_organizations: ItemCountWithMax
  parent_org?: ItemLite
  permission?: Permission
  trial_end?: string
  parent_profile?: ItemLite
}

type ItemLite = {
  name: string
  PK: string
}

export enum OrgPermissionLevel {
  OWNER = 100,
  ADMIN = 10,
  VIEWER = 1,
  NONE = 0,
}

export type Permission = {
  level: OrgPermissionLevel
  printable: string
}

export type Member = {
  PK: string
  email: string
  last_active: number
  twofa: EnabledStatus
  status: EnabledStatus
  permission: Permission
  sso?: SSOProvider
}

export type GetOrganizationsResponse = ApiResponse<{
  organization: Organization
  sub_organizations: Organization[]
  members: Member[]
}>

export type GetOrganizationResponse = ApiResponse<{
  organization: Organization
}>

export type GetMemberResponse = ApiResponse<{
  members: Member[]
}>

export type GetSubOrganizationsResponse = ApiResponse<{
  sub_organizations: Organization[]
}>

export type OrganizationsResponseType = {
  data: GetOrganizationsResponse
} & { error: { data: { error: SerializedError } } }

export interface SubOrgRequestBody {
  name: string
  contact_email: string
  twofa_req: number
  stats_endpoint: string
  address?: string
  website?: string
  contact_name?: string
  parent_profile?: string
}

export type CheckInviteCodeResponse = ApiResponse<{
  organization: {
    name: string
    PK: string
    email: string
  }
}>

export type ModifyAdminRequest = { adminPk: string; permission: Permission }
