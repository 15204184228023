import palette from './palette'
import { ColorRoleToPaletteMap, ThemeMode } from './themeObject.interface'

// TODO: dark theme colors should be updated
// generally, colors that are subject to change
const colorMap: ColorRoleToPaletteMap = {
  // Test colors (colors should be used only from palette)
  primary: 'greenApple',
  primaryShadow: 'darkItemBoxShadow',
  secondary: 'darkItemBG50',
  text: 'white',
  background: 'blue800',
  cardBackground: 'black',
  cardShadow: 'black',
  modalBackground: 'darkItemBG50',
  accent: 'white15',
  muted: 'white50',
  error: 'pomegranate',
  alertError: 'pomegranate15',
  active: 'white25',
  successBackground: 'greenApple15',
  scrollbarThumb: 'darkItemBG50',
  staticPageBackground: 'black',
  dialogElementBg: 'blueYonder10',
  dialogBodyBg: 'smokyBlack',
  subTextColor: 'aliceBlue60',
  textColor: 'lightSteelBlue',
  labelColor: 'aliceBlue',
  barryLink: 'white50',

  greenApple: 'greenApple', // green
  blue800: 'blue800', // body bg
  plumpPurple: 'plumpPurple', // purple
  aliceBlue: 'aliceBlue', // text, icon
  aliceBlue30: 'aliceBlue30', // text, icon
  aliceBlue60: 'aliceBlue60', // text, icon
  aliceBlue60Black60: 'aliceBlue60',
  aliceBlue70: 'aliceBlue70', // text, icon
  white: 'white', // tertiary button cta
  darkJungleGreen: 'darkJungleGreen', // dropdown bg
  black: 'black', // toggle knob bg
  blue: 'blue', // rule record and comment bg
  cyan800: 'cyan800', // input field border focus
  white50: 'white50',
  white25: 'white25',
  white10: 'white10', // card bg hover
  white8: 'white8', // card bg hover
  white6: 'white6', // card bg
  cardBg: 'white6', // card bg
  white4: 'white4', // field bg default
  blueYonder80: 'blueYonder80', // input field border hover
  blueYonder40: 'blueYonder40', // input field border
  blueYonder30: 'blueYonder30', // border strong
  blueYonder20: 'blueYonder20', // input field disabled bg
  blueYonder15: 'blueYonder15', // dividers
  darkBodyBG: 'darkBodyBG',
  grayLogoBG: 'grayLogoBG',
  white60: 'white60',
  darkItemBG: 'darkItemBG',
  raisinBlack: 'raisinBlack',
  darkItemBG50: 'darkItemBG50',
  lightSteelBlue: 'lightSteelBlue',
  smokyBlack: 'smokyBlack',
  aliceBlue40: 'aliceBlue40',
  blackKnight: 'blackKnight',
  white15: 'white15',

  commonWhite: 'aliceBlue',
  commonBlack: 'black',
  commonWhite60: 'aliceBlue60',
  commonAliceBlue30: 'aliceBlue30',
  commonCyan800: 'cyan800',
  confirmAlertBG: 'stellarExplorer90',
}

const colors = Object.entries(colorMap).reduce((acc, [k, v]) => {
  acc[k] = palette[v]
  return acc
}, {})

const darkModeObj: ThemeMode = {
  dark: {
    ...colors,
  },
}

export default darkModeObj
