import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { Svg } from 'ui'
import GreenCheckmarkIcon from 'images/plans/green-checkmark.svg'
import GreyCheckmarkIcon from 'images/plans/grey-checkmark.svg'
import InfoIcon from 'images/plans/info-icon.svg'
import IconWithTippyPopup from 'ui/IconWithTippyPopup'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

interface FeatureProps {
  type?: string
  name: string | ReactElement
  tooltip?: string
}
function Feature({ name, tooltip, type }: FeatureProps): ReactElement {
  const isMobile = useBreakpointIndex() === 0

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Svg
          svg={type ? GreenCheckmarkIcon : GreyCheckmarkIcon}
          sx={{ width: '2.4rem', height: '2.4rem' }}
        />
        <Text
          variant={isMobile ? 'size14Weight500Line138' : 'size17Weight500Line138'}
          sx={{
            ml: ['0.8rem', '1.6rem'],
            color: type ? 'greenApple' : 'aliceBlue70',
          }}
        >
          {name}
        </Text>
      </Flex>
      {tooltip && (
        <Flex
          sx={{
            alignItems: 'center',
            ml: '0.4rem',
          }}
        >
          <IconWithTippyPopup
            content={tooltip}
            ariaLabel={tooltip}
            svg={InfoIcon}
            sx={{
              width: '1.6rem',
              height: '1.6rem',
            }}
            sxButton={{
              ':hover': {
                '& > svg g': {
                  opacity: 1,
                },
              },
            }}
            maxWidth={280}
          />
        </Flex>
      )}
    </Flex>
  )
}
export default Feature
