import React, { ReactNode, ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { Svg, Button } from 'ui'

interface PlanProps {
  name: ReactNode
  priceElement: ReactNode
  color: string
  features: ReactNode
  icon: React.ElementType
  onSelect: () => void
  isCurrentPlan: boolean
}
function Plan({
  name,
  priceElement,
  color,
  features,
  icon,
  onSelect,
  isCurrentPlan,
}: PlanProps): ReactElement {
  return (
    <Flex
      data-testid={name}
      sx={{
        flexDirection: 'column',
        backgroundColor: 'smokyBlack',
        borderRadius: '1.2rem',
        height: ['fit-content', '60rem'],
      }}
    >
      <Flex
        sx={{
          backgroundColor: 'smokyBlack80',
          borderRadius: '1.2rem',
          border: '1px solid',
          borderColor: 'blueYonder50',
          p: '2.4rem',
          flexDirection: 'column',
          flex: 1,
          gap: '2.4rem',
        }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <Flex>
            <Svg svg={icon} />
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              textAlign: 'right',
              fontWeight: 'bold',
              ml: 'auto',
              color,
            }}
          >
            {isCurrentPlan && (
              <Text
                variant="size14Weight600Line138Letter056"
                sx={{
                  color: 'greenApple',
                  textTransform: 'uppercase',
                }}
              >
                Current plan
              </Text>
            )}
            <Text variant="size28Weight600">{name}</Text>
          </Flex>
        </Flex>
        <Flex
          sx={{
            fontWeight: 'bold',
            fontSize: '2.8rem',
            height: 'fit-content',
          }}
        >
          {priceElement}
        </Flex>
        <Flex
          sx={{
            justifyContent: 'center',
          }}
        >
          <Button
            data-testid="select-plan-button"
            variant="gradientButton"
            sx={{
              minWidth: '100%',
            }}
            ariaLabel={`Select ${name}`}
            onClick={onSelect}
          >
            <Text variant="size17Weight500Line138">Select</Text>
          </Button>
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
          }}
        >
          {features}
        </Flex>
      </Flex>
    </Flex>
  )
}
export default Plan
