import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'

import MalwareBlockingIcon from 'images/organization/homepage/headerNavMenu/featuresIcons/malware-block-icon.svg'
import WebFilteringIcon from 'images/organization/homepage/headerNavMenu/featuresIcons/web-filtering.svg'
import ServiceFilteringIcon from 'images/organization/homepage/headerNavMenu/featuresIcons/service-filter-fixed.svg'
import CustomFilteringIcon from 'images/organization/homepage/headerNavMenu/featuresIcons/custom-filtering.svg'
import ModernProtocolsIcon from 'images/organization/homepage/headerNavMenu/featuresIcons/modern-protocols.svg'
import AnalyticsIcon from 'images/organization/homepage/headerNavMenu/featuresIcons/analytics.svg'
import DataStreamingIcon from 'images/organization/homepage/headerNavMenu/featuresIcons/data-streaming.svg'
import TrafficRedirectionIcon from 'images/organization/homepage/headerNavMenu/featuresIcons/traffic-redirection.svg'
import MultiTenancyIcon from 'images/organization/homepage/headerNavMenu/featuresIcons/multi-tenancy.svg'
import AdminLogsIcon from 'images/organization/homepage/headerNavMenu/featuresIcons/admin-logs.svg'
import BrowseFasterIcon from 'images/homePage/features/browse-faster.svg'
import ImproveProductivityIcon from 'images/homePage/features/improve-productivity.svg'
import SwitchLocationIcon from 'images/homePage/features/switch-location.svg'
import ProtectKidsIcon from 'images/homePage/features/protect-kids.svg'
import CustomizeProfilesIcon from 'images/homePage/features/customize-profiles.svg'
import AvailableServicesIcon from 'images/homePage/features/available-services.svg'

export const orgFeatures = [
  {
    header: 'Malware Blocking',
    icon: MalwareBlockingIcon,
    description: 'Reduce the chance of a compromise.',
    href: '/features/malware-blocking',
  },
  {
    header: 'Modern Protocols',
    icon: ModernProtocolsIcon,
    description: 'Use secure and encrypted DNS protocols.',
    to: makeUrl('feature-modern-protocols'),
  },
  {
    header: 'Web Filtering',
    icon: WebFilteringIcon,
    description: 'Block access to unwanted content.',
    to: makeUrl('feature-web-filtering'),
  },
  {
    header: 'Analytics',
    icon: AnalyticsIcon,
    description: 'Gain insights with comprehensive analytics.',
    to: makeUrl('feature-analytics'),
  },
  {
    header: 'Service Filtering',
    icon: ServiceFilteringIcon,
    description: 'Block individual web services and apps.',
    to: makeUrl('feature-service-filtering'),
  },
  {
    header: 'Data Streaming (SIEM)',
    icon: DataStreamingIcon,
    description: 'Stream raw query logs into your SIEM.',
    to: makeUrl('feature-data-streaming-siem'),
  },
  {
    header: 'Custom Filtering',
    icon: CustomFilteringIcon,
    description: 'Control DNS resolution of any domain name.',
    to: makeUrl('feature-custom-filtering'),
  },
  {
    header: 'Traffic Redirection',
    icon: TrafficRedirectionIcon,
    description: 'Redirect traffic through network of proxies.',
    to: makeUrl('feature-traffic-redirection'),
  },
  {
    header: 'Multi-Tenancy',
    icon: MultiTenancyIcon,
    description: 'Group assets into sub-organizations.',
    to: makeUrl('feature-multi-tenancy'),
  },
  {
    header: 'Admin Logs',
    icon: AdminLogsIcon,
    description: 'Keep a paper trail of all admin actions.',
    to: makeUrl('feature-admin-logs'),
  },
]

export const personalFeatures = [
  {
    header: 'Browse Faster',
    icon: BrowseFasterIcon,
    href: '/browse-faster',
  },
  {
    header: 'Improve Productivity',
    icon: ImproveProductivityIcon,
    href: '/improve-productivity',
  },
  {
    header: 'Switch Your Location',
    icon: SwitchLocationIcon,
    href: '/teleport',
  },
  {
    header: 'Protect Your Kids',
    icon: ProtectKidsIcon,
    href: '/parental-controls',
  },
  {
    header: 'Customize Multiple Profiles',
    icon: CustomizeProfilesIcon,
    href: '/multi-device',
  },
  {
    header: 'Available Services',
    icon: AvailableServicesIcon,
    href: '/available-services',
  },
]

function makeUrl(path: string): string {
  return `${DOCS_CONTROL_D_DOMAIN}/docs/${path}`
}
