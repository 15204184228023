import React, { ReactElement, ReactNode } from 'react'
import { Flex, ThemeUIStyleObject, Text } from 'theme-ui'

const borderStyles = {
  content: '""',
  borderBottom: '1px solid',
  flex: 1,
}

export default function DividerWithText({
  text,
  sx,
  textPlacement = 'center',
  lineColor = 'blueYonder30',
  children,
}: {
  text?: string
  children?: ReactNode
  sx?: ThemeUIStyleObject
  textPlacement?: 'left' | 'center' | 'right'
  lineColor?: string
}): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        flexShrink: 0,
        alignItems: 'center',
        fontWeight: 'bold',
        gap: '0.8rem',
        '&:before': ['center', 'right'].includes(textPlacement)
          ? {
              ...borderStyles,
              borderColor: lineColor,
            }
          : {},
        '&:after': ['center', 'left'].includes(textPlacement)
          ? {
              ...borderStyles,
              borderColor: lineColor,
            }
          : {},
        ...sx,
      }}
    >
      <Text variant="size12Weight700Line138Letter048" sx={{ color: 'aliceBlue' }}>
        {text || children}
      </Text>
    </Flex>
  )
}
