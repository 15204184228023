export default {
  // button, tab text, dropdown item
  size15Weight600: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  // card label, heading, warning text, table header
  size15Weight700: {
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  size15Weight500: {
    fontSize: '1.5rem',
    fontWeight: 500,
  },
  // section title
  size15Weight400: {
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  // description
  size12Weight400: {
    fontSize: '1.2rem',
    fontWeight: 400,
  },
  size12Weight500: {
    fontSize: '1.2rem',
    fontWeight: 500,
  },
  size12Weight600: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  // date shortcuts
  size12Weight700: {
    fontSize: '1.2rem',
    fontWeight: 700,
  },
  size12Weight600Line138: {
    fontSize: '1.2rem',
    fontWeight: 700,
    lineHeight: '138%',
  },
  // count
  size12Weight700Letter018: {
    fontSize: '1.2rem',
    fontWeight: 700,
    letterSpacing: 0.18,
  },
  // page heading
  size40Weight600: {
    fontSize: '4rem',
    fontWeight: 600,
  },
  // title, modal title
  size21Weight600Line138: {
    fontSize: '2.1rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  size21Weight400Line138: {
    fontSize: '2.1rem',
    fontWeight: 400,
    lineHeight: '138%',
  },
  size21Weight500Line138: {
    fontSize: '2.1rem',
    fontWeight: 500,
    lineHeight: '138%',
  },
  // sub title, dialog text, card content
  size16Weight400: {
    fontSize: '1.6rem',
    fontWeight: 400,
  },
  // sub title bold
  size16Weight700: {
    fontSize: '1.6rem',
    fontWeight: 700,
  },
  size12Weight400Uppercase: {
    fontSize: '1.2rem',
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  // section label
  size12Weight700Uppercase: {
    fontSize: '1.2rem',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  // section title, button text, tab text
  size17Weight600Line138: {
    fontSize: '1.7rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  // ips info
  size17Weight400: {
    fontSize: '1.7rem',
    fontWeight: 400,
  },
  // mobile section heading
  size28Weight600: {
    fontSize: '2.8rem',
    fontWeight: 600,
  },
  size14Weight700: {
    fontSize: '1.4rem',
    fontWeight: 700,
  },
  size14Weight600: {
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  size14Weight500Line138: {
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: '138%',
  },
  size14Weight500Line138Letter056: {
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: '138%',
    letterSpacing: 0.56,
  },
  size14Weight600Line138Letter056: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '138%',
    letterSpacing: 0.56,
  },
  size14Weight400Line138Letter056: {
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: '138%',
    letterSpacing: 0.56,
  },
  size14Weight400: {
    fontSize: '1.4rem',
    fontWeight: 400,
  },
  // dialog text
  size14Weight400Line138: {
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: '138%',
    letterSpacing: 0.56,
  },
  size14Weight700Line138: {
    fontSize: '1.4rem',
    fontWeight: 700,
    lineHeight: '138%',
    letterSpacing: 0.56,
  },
  // badge
  size9Weight700: {
    fontSize: '0.9rem',
    fontWeight: 700,
  },
  size8Weight700: {
    fontSize: '0.8rem',
    fontWeight: 700,
  },
  size12Weight700Line138Letter048: {
    fontSize: '1.2rem',
    fontWeight: 700,
    lineHeight: '138%',
    letterSpacing: '0.48',
  },
  size12Weight400Line138Letter048: {
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: '138%',
    letterSpacing: '0.48',
  },
  size16Weight400Line150: {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: '150%',
  },
  size16Weight400Line140: {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: '140%',
  },
  size16Weight400Line140Letter08: {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '0.08px',
  },
  size16Weight600Line138: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  size16Weight600Line140: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: '140%',
  },
  size18Weight400Line140: {
    fontSize: '1.8rem',
    fontWeight: 400,
    lineHeight: '140%',
  },
  size17Weight700Line138: {
    fontSize: '1.7rem',
    fontWeight: 700,
    lineHeight: '138%',
  },
  size18Weight500Line140: {
    fontSize: '1.8rem',
    fontWeight: 500,
    lineHeight: '140%',
  },
  size19Weight600Line138: {
    fontSize: '1.9rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  size16Weight500Line138: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '138%',
  },
  size16Weight500Line138Letter032: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '138%',
    letterSpacing: '0.32px',
  },
  size13Weight500Line136Letter013: {
    fontSize: '1.3rem',
    fontWeight: 500,
    lineHeight: '136%',
    letterSpacing: '0.13px',
  },
  size17Weight500Line138: {
    fontSize: '1.7rem',
    fontWeight: 500,
    lineHeight: '138%',
  },
  size17Weight700: {
    fontSize: '1.7rem',
    fontWeight: 700,
  },
  size18Weight800Line140: {
    fontSize: '1.8rem',
    fontWeight: 800,
    lineHeight: '140%',
  },
  size18Weight700: {
    fontSize: '1.8rem',
    fontWeight: 700,
  },
  size16Weight500Line140Letter008: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '140%',
    letterSpacing: 0.08,
  },
  size15Weight700Line138: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '138%',
  },
  size15Weight600Line138: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  size14Weight600Line138: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  size15Weight400Line138: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '138%',
  },
  size12Weight400Line138: {
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: '138%',
  },
  size11Weight700: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },
  size11Weight400: {
    fontSize: '1.1rem',
    fontWeight: 400,
  },
  size10Weight400: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  size10Weight700: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  size32Weight700Line138: {
    fontSize: '3.2rem',
    fontWeight: 700,
    lineHeight: '138%',
  },
  size13Weight700: {
    fontSize: '1.3rem',
    fontWeight: 700,
  },
  size13Weight300Line138: {
    fontSize: '1.3rem',
    fontWeight: 300,
    lineHeight: '138%',
  },
  size21Weight700: {
    fontSize: '2.1rem',
    fontWeight: 700,
  },
  size20Weigh600Line138: {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  size19Weigh600Line138: {
    fontSize: '1.9rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  size16Italic: {
    fontSize: '1.6rem',
    fontStyle: 'italic',
  },
  size16Weight400Line140Italic: {
    fontSize: '1.6rem',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '140%',
  },
  size44Weight400: {
    fontSize: '4.4rem',
    fontWeight: 400,
  },
  size32Weight500: {
    fontSize: '3.2rem',
    fontWeight: 600,
  },
  size52Weight600: {
    fontSize: '5.2rem',
    fontWeight: 600,
  },
  size48Weight600: {
    fontSize: '4.8rem',
    fontWeight: 600,
  },
  size24Weight500: {
    fontSize: '2.4rem',
    fontWeight: 500,
  },
  size24Weight600: {
    fontSize: '2.4rem',
    fontWeight: 600,
  },
}
