import React, { ReactElement, useEffect, useState } from 'react'
import { Box, Flex } from 'theme-ui'
import { useHotkeys } from 'react-hotkeys-hook'
import FocusLock from 'react-focus-lock'
import { ModalDialogProps } from './ModalDialog.interface'
import ModalDialogHeader from './ModalDialogHeader'
import ModalDialogFooter from './ModalDialogFooter'
import useOnClickOutside from 'utils/useOnClickOutside'
import CreatePortalWrapper from 'components/CreatePortalWrapper'

export const modalDialogWidth = ['100%', 'fit-content', 'fit-content']

export default function ModalDialog({
  dismiss = (): void => {},
  isOpen,
  onPresentTransitionEnd,
  onDismissTransitionEnd,
  children,
  sx,
  sxOverlay,
  dataTestId = 'modal-dialog-body',
  shouldVibrateOnPresent = false,
  showOverlay = true,
  overlayContent,
  modalDialogRef,
  disableFocusLock,
  title,
  sxHeaderContainer,
  headerRef,
  onCloseClick,
  onBackClick,
  footer,
  isLeftContentVisible,
  isRightContentVisible,
  sxFooterContainer,
  leftContent,
  sxContentContainer,
  shouldCreatePortal = true,
  shouldHideFooter,
}: ModalDialogProps): ReactElement | null {
  const [isFocusLockDisabled, setIsDisabledFocusLock] = useState(true)

  useOnClickOutside([], () => setIsDisabledFocusLock(true))

  useHotkeys(
    'Tab',
    () => {
      setIsDisabledFocusLock(false)
    },
    [],
  )

  useHotkeys(
    'Escape',
    () => {
      isOpen && dismiss()
    },
    [isOpen],
  )

  const handleTransitionEnd = (): void => {
    if (isOpen && onPresentTransitionEnd) {
      onPresentTransitionEnd()
    } else if (!isOpen && onDismissTransitionEnd) {
      onDismissTransitionEnd()
    }
  }

  useEffect(() => {
    if (shouldVibrateOnPresent && isOpen && window.navigator.vibrate) {
      try {
        window.navigator.vibrate(10)
      } catch (e) {}
    }
    // we only want to trigger this effect when `isOpen` changes, not when
    // `shouldVibrateOnPresent` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  return (
    <CreatePortalWrapper shouldCreatePortal={shouldCreatePortal}>
      <>
        {showOverlay && (
          <Box
            onTransitionEnd={handleTransitionEnd}
            onClick={(): void => dismiss()}
            sx={{
              display: ['none', 'inherit'],
              pt: '10rem',
              visibility: isOpen ? 'inherit' : 'hidden',
              transition: 'opacity 0.2s ease',
              backgroundColor: 'eerieBlack80',
              width: showOverlay ? '100%' : 0,
              height: showOverlay ? '100%' : 0,
              position: 'fixed',
              left: '0px',
              top: '0px',
              zIndex: 'zIndex595',
              ...sxOverlay,
            }}
          >
            {overlayContent}
          </Box>
        )}
        <FocusLock autoFocus={false} disabled={disableFocusLock || isFocusLockDisabled || !isOpen}>
          <Box
            ref={modalDialogRef}
            className={`modal-dialog ${isOpen ? 'transition' : ''}`}
            aria-modal="true"
            data-testid={dataTestId}
            aria-live="polite"
            aria-hidden={!isOpen}
            sx={{
              display: isOpen ? 'flex' : 'none',
              flexDirection: 'column',
              width: modalDialogWidth,
              maxHeight: ['100%', 'calc(100% - 4.8rem)'],
              height: ['100%', 'fit-content'],
              willChange: 'transform',
              backgroundColor: 'blue800',
              position: 'fixed',
              left: '50%',
              top: '50%',
              borderRadius: [0, '1.2rem'],
              transform: 'translate(-50%, -50%)',
              zIndex: 'zIndex596',
              border: '1px solid',
              borderColor: 'blueYonder30',
              boxShadow: '0px 5px 45px -5px rgba(0, 0, 0, 45%)',
              ...sx,
            }}
            tabIndex={isOpen ? -1 : 0}
          >
            {title && (
              <ModalDialogHeader
                title={title}
                headerRef={headerRef}
                sx={sxHeaderContainer}
                onCloseClick={onCloseClick}
                onBackClick={onBackClick}
                leftContent={leftContent}
                isLeftContentVisible={isLeftContentVisible}
                isRightContentVisible={isRightContentVisible}
              />
            )}
            <Flex
              sx={{
                flex: [1, 'auto'],
                alignItems: 'flex-start',
                backgroundColor: 'cardBg',
                overflow: 'hidden',
                borderRadius: footer ? 0 : [0, '0 0 1.2rem 1.2rem'],
                ...sxContentContainer,
              }}
            >
              {children}
            </Flex>
            {footer && (
              <ModalDialogFooter sx={sxFooterContainer} shouldHideFooter={shouldHideFooter}>
                {footer}
              </ModalDialogFooter>
            )}
          </Box>
        </FocusLock>
      </>
    </CreatePortalWrapper>
  )
}
