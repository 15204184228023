import React, { ReactElement, useCallback, useMemo } from 'react'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { Button, Svg, useAlertPresenter } from 'ui'
import BookIcon from 'images/book.svg'
import ResolverTabs from 'components/SetupGuide/ResolverTabs/ResolverTabs'
import {
  ConfiguredButton,
  ConfiguredStatusBanner,
  Footer,
  PreviousStepButton,
} from 'components/SetupGuide/Components'
import { SetupGuideIntermediateStates, SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import useStepHelper from 'components/SetupGuide/useStepHelper'
import { troubleshootingAlertName } from 'components/SetupGuide/SetupGuide'
import useQueryString from 'utils/useQueryString'
import { ResolverStatus } from 'store/api/user/user.interface'
import { TrayOption } from 'ui/Tray/Tray.interface'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setHasTutorialBeenViewed, TutorialType } from 'store/tutorial/tutorial'
import GearIcon from 'images/gear.svg'
import DividerWithText from 'ui/DividerWithText'
import {
  TrackingEventAction,
  TrackingEventCategory,
  TrackingEventName,
  useTrackingHelper,
} from 'utils/useTrackingHelper'
import ExternalLinkOrButton from 'components/SetupGuide/Components/ExternalLinkOrButton'
import DeviceIcons from 'components/Dashboard/Devices/DeviceIcons'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { useGetDevicesQuery } from 'store/api/devices'
import { useGetUserQuery } from 'store/api/user'
import SetupGuideDialog from '../Components/SetupGuideDialog'
import { HighlightProtocolInSetupGuide } from 'store/api/devices/devices.interface'
import useResolverTabHighlight from 'components/SetupGuide/ResolverTabs/Components/useResolverTabHighlight'
import useStartSetupFlow from 'components/SetupGuide/useStartSetupFlow'

const buttonStyles: ThemeUIStyleObject = {
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'center',
  height: '3.8rem',
  border: '1px solid',
  borderColor: 'blueYonder30',
  borderRadius: '2rem',
  backgroundColor: 'transparent',
  '&:hover': {
    ':not([disabled])': {
      backgroundColor: 'transparent',
      transform: 'scale(1)',
    },
  },
}

export const buttonStylesWithPaddings: ThemeUIStyleObject = {
  ...buttonStyles,
  p: '1.2rem',
  flexShrink: 0,
}

export const autoConfigurablePlatforms: SetupOs[] = [
  SetupOs.MAC,
  SetupOs.WINDOWS,
  SetupOs.LINUX,
  SetupOs.ASUS,
  SetupOs.DD_WRT,
  SetupOs.OPEN_WRT,
  SetupOs.UBIQUITI,
  SetupOs.FRESH_TOMATO,
  SetupOs.GL_INET,
  SetupOs.SYNOLOGY,
  SetupOs.PF_SENSE,
  SetupOs.OPN_SENSE,
  SetupOs.GENERIC_LINUX,
  SetupOs.WINDOWS_SERVER,
  SetupOs.FIREWALLA,
  SetupOs.ANDROID,
  SetupOs.IOS,
]

const ShowUserDns = (): ReactElement => {
  const { qs, nav, setupGuideNav } = useQueryString()
  const { dismissAlert } = useAlertPresenter()
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: userData } = useGetUserQuery('', { skip: !sessionToken })
  const userPk = userData?.PK
  const dispatch = useAppDispatch()
  const hasSetupGuideBeenCompletedBefore = useAppSelector(
    s => s.tutorial.viewedStateByUserPk[userPk ?? '']?.[TutorialType.SETUP_GUIDE]?.hasBeenViewed,
  )

  const { goToPreviousStep, configuredStatus, closePane } = useStepHelper()
  const { data: devicesData } = useGetDevicesQuery('', { skip: !sessionToken })
  const selectedDevice = devicesData?.devices.find(d => d.PK === qs.deviceId)
  const deviceResolvers = useMemo(
    () => devicesData?.devices?.find(f => f.PK.toString() === selectedDevice?.PK)?.resolvers,
    [devicesData?.devices, selectedDevice?.PK],
  )
  const isOtherRouter = useMemo(
    () => !!qs.setupOs && [SetupOs.ROUTER_OTHER].includes(qs.setupOs),
    [qs.setupOs],
  )

  function checkIfHighlightedTabsExist(selectedDevice, highlightedProtocols) {
    if (!selectedDevice || !highlightedProtocols) {
      return false
    }

    // should be every to get correct result
    return highlightedProtocols?.every(protocol => {
      let protocolProp = protocol as string

      if (protocol === HighlightProtocolInSetupGuide.IPv6) {
        protocolProp = 'v6'
      }

      if (protocol === HighlightProtocolInSetupGuide.IPv4) {
        protocolProp = 'v4'
      }

      if (protocol === HighlightProtocolInSetupGuide.ResolverID) {
        protocolProp = 'uid'
      }

      return !!selectedDevice?.resolvers[protocolProp]
    })
  }

  const configuredButtonHandler = useCallback(() => {
    dispatch(
      setHasTutorialBeenViewed({
        tutorialType: TutorialType.SETUP_GUIDE,
        hasBeenViewed: true,
        userPk: userPk || '',
      }),
    )
    dismissAlert(troubleshootingAlertName)
    if (!hasSetupGuideBeenCompletedBefore) {
      nav({ tray: TrayOption.WHERE_TO_GO })
    } else {
      closePane()
    }
  }, [closePane, dismissAlert, nav, dispatch, hasSetupGuideBeenCompletedBefore, userPk])

  const isResolved =
    configuredStatus === ResolverStatus.VERIFIED || !!qs.freeResolverType || !!qs.resolverUid

  const { trackEvent } = useTrackingHelper()

  const { highlight } = useResolverTabHighlight(selectedDevice ? selectedDevice.icon : qs.setupOs)
  const isHighlightedTabVisible = checkIfHighlightedTabsExist(selectedDevice, highlight)

  const { auto: startAutoSetup } = useStartSetupFlow()

  const isProvisioning = !!qs.resolverUid

  const onHelpMeButtonClick = useCallback((): void => {
    const { setupOs } = qs

    trackEvent({
      category: TrackingEventCategory.Nav,
      action: TrackingEventAction.Tutorial,
      name: setupOs?.toLowerCase() as TrackingEventName,
    })

    if (setupOs && autoConfigurablePlatforms.includes(setupOs)) {
      setupGuideNav({ ...qs, helpPane: SetupGuideIntermediateStates.Configure })
      return
    }

    switch (setupOs) {
      case undefined:
        setupGuideNav({ ...qs, helpPane: SetupGuideIntermediateStates.Platform })
        break
      case SetupOs.APPLE_TV:
        setupGuideNav({ ...qs, helpPane: SetupGuideIntermediateStates.AppleTVVersion })
        break
      default:
        setupGuideNav({ ...qs, helpPane: qs.setupOs, setupOs: qs.setupOs, step: '0' })
        break
    }
  }, [qs, setupGuideNav, trackEvent])

  return (
    <SetupGuideDialog
      isOpen={qs.deviceId ? !!deviceResolvers : true}
      title="Endpoint Resolvers"
      onClose={() => closePane()}
      footer={
        qs.helpPane === SetupGuideIntermediateStates.DNS ? (
          <Flex
            sx={{
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <ExternalLinkOrButton
              dataTestId="setup-guide-help-me-button"
              isButton={
                !qs.setupOs || // setupOS will not be there if it is other device
                (!!qs.setupOs &&
                  Object.values(SetupOs).includes(qs.setupOs) && // only for known SetupOs fields will show the Setup Guide
                  // todo : remove the item from the list below once the guide is in place. Till then defaulting to the KB
                  ![
                    //router
                    SetupOs.ROUTER_OTHER,
                    // browser
                    SetupOs.BRAVE,
                    SetupOs.BROWSER_OTHER,
                    // TV
                    SetupOs.TV_OTHER,
                  ].includes(qs.setupOs))
              }
              href={
                !!qs.setupOs && isOtherRouter
                  ? `${DOCS_CONTROL_D_DOMAIN}/docs/routers-platform`
                  : `${DOCS_CONTROL_D_DOMAIN}/docs/supported-platforms`
              }
              commonSx={{
                ...buttonStylesWithPaddings,
                width: ['100%', 'auto'],
              }}
              onClick={onHelpMeButtonClick}
            >
              <Svg fill="aliceBlue" svg={BookIcon} sx={{ mr: '0.8rem' }} />
              <Text
                sx={{
                  color: 'aliceBlue',
                }}
                variant="size15Weight700"
              >
                Help Me Configure
              </Text>
            </ExternalLinkOrButton>
          </Flex>
        ) : (
          <Footer
            previousButton={<PreviousStepButton onClick={goToPreviousStep} />}
            nextButton={<ConfiguredButton {...{ onClick: configuredButtonHandler, isResolved }} />}
            textBanner={<ConfiguredStatusBanner configuredStatus={configuredStatus} />}
          />
        )
      }
    >
      <Flex
        data-testid="show-dns-root"
        sx={{
          width: ['100%', '45.2rem'],
          flexDirection: 'column',
          alignItems: 'flex-start',
          overflowY: 'auto',
          maxHeight: ['100%', '80dvh'],
        }}
        className="show-scrollbar"
      >
        {isProvisioning && (
          <Flex
            sx={{
              flexDirection: 'column',
            }}
          >
            {qs.setupOs && autoConfigurablePlatforms.includes(qs.setupOs) && (
              <>
                <Flex
                  data-testid="automatic-setup-section"
                  sx={{
                    gap: '1.6rem',
                    alignItems: 'center',
                    flex: 1,
                    p: '2.4rem',
                  }}
                >
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      gap: '0.8rem',
                      flex: 1,
                    }}
                  >
                    <Text
                      variant="size12Weight700Line138Letter048"
                      sx={{ textTransform: 'uppercase', color: 'aliceBlue' }}
                    >
                      Automatic Setup (use app)
                    </Text>
                    <Text variant="size12Weight400" sx={{ color: 'aliceBlue60' }}>
                      Configure Control D on this device by downloading an app.
                    </Text>
                  </Flex>
                  <Button
                    data-testid="setup-guide-automatic-setup-button"
                    ariaLabel="setup guide automatic setup button"
                    variant="newSecondary"
                    onClick={startAutoSetup}
                    sx={{
                      height: '3.8rem',
                      fontSize: ['1.2rem', 'unset'],
                    }}
                  >
                    <Svg fill="white" svg={GearIcon} sx={{ mr: '0.8rem' }} />
                    <Text sx={{ color: 'aliceBlue' }}>Automatic Setup</Text>
                  </Button>
                </Flex>
                <DividerWithText>
                  <Flex sx={{ p: '0.8rem', backgroundColor: 'blue800', borderRadius: '50%' }}>
                    <Text sx={{ color: 'aliceBlue' }} variant="size12Weight700Line138Letter048">
                      OR
                    </Text>
                  </Flex>
                </DividerWithText>
              </>
            )}
            <Flex
              sx={{
                width: '100%',
                gap: '1.6rem',
                alignItems: 'center',
                justifyContent: 'flex-start',
                p: '2.4rem',
              }}
            >
              <Flex sx={{ flexDirection: 'column', gap: '0.8rem' }}>
                <Text
                  variant="size12Weight700Line138Letter048"
                  sx={{ textTransform: 'uppercase', color: 'aliceBlue' }}
                >
                  manual setup (advanced)
                </Text>
                <Text variant="size12Weight400" sx={{ color: 'aliceBlue60' }}>
                  {isHighlightedTabVisible
                    ? 'Use the highlighted DNS resolver below to configure this device.'
                    : 'Use one of the DNS resolvers below to configure this device.'}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )}

        {selectedDevice && (
          <Flex
            sx={{
              width: '100%',
              gap: '1.6rem',
              alignItems: 'center',
              justifyContent: 'center',
              p: '1.2rem 2.4rem',
            }}
          >
            <Flex
              sx={{
                gap: '0.8rem',
                minWidth: '14rem',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'fit-content',
              }}
            >
              <Svg
                svg={DeviceIcons[selectedDevice.icon ?? 'default']}
                fill="aliceBlue"
                sx={{ height: '2.4rem', width: '2.4rem', flexShrink: 0 }}
              />
              <TextWithOverFlowAndTippyPopup
                variant="size12Weight400"
                content={selectedDevice.name}
                ariaLabel={selectedDevice.name}
                sxText={{ color: 'aliceBlue' }}
              />
            </Flex>
          </Flex>
        )}
        <ResolverTabs />
      </Flex>
    </SetupGuideDialog>
  )
}

export default ShowUserDns
