import React from 'react'
import { Flex, Text } from 'theme-ui'
import { Button, Svg } from 'ui'
import ConfirmEmailIcon from 'images/organization/confirm-email-icon.svg'
import ConfirmEmailDarkMobileIcon from 'images/organization/confirm-email-mobile-icon.svg'
import ConfirmEmailLightMobileIcon from 'images/organization/confirm-email-mobile-light-icon.svg'
import Overlay from 'ui/Overlay'
import { navigate } from 'gatsby'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import useGetColorMode from 'utils/useGetColorMode'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'

export default function ConfirmEmailScreen(): React.ReactElement {
  const { data: userData } = useGetUser()
  const signUpEmailAddress = userData?.email
  const isMobile = useBreakpointIndex() === 0
  const { isLightMode } = useGetColorMode()

  return (
    <Overlay>
      <Flex
        sx={{
          flexDirection: 'column',
          textAlign: 'center',
          alignItems: 'center',
          px: '1.6rem',
        }}
      >
        <Text
          variant={isMobile ? 'size28Weight600' : 'size48Weight600'}
          sx={{
            mb: ['4.8rem', '8rem'],
            color: 'aliceBlue',
          }}
        >
          Confirm your email.
        </Text>
        <Svg
          svg={
            isMobile
              ? isLightMode
                ? ConfirmEmailLightMobileIcon
                : ConfirmEmailDarkMobileIcon
              : ConfirmEmailIcon
          }
        />
        <Text
          variant={isMobile ? 'size16Weight400Line140' : 'size18Weight400Line140'}
          sx={{
            display: 'inline-flex',
            flexDirection: 'column',
            maxWidth: ['100%', '60rem'],
            mt: '2.4rem',
            color: 'aliceBlue',
            mb: '4.8rem',
          }}
        >
          To finish up, please confirm your email{' '}
          <TextWithOverFlowAndTippyPopup
            ariaLabel="sign up email address"
            variant={isMobile ? 'size16Weight600Line140' : 'size18Weight600Line140'}
            sxText={{ color: 'greenApple' }}
            content={signUpEmailAddress}
          />{' '}
          to get access to your dashboard.
        </Text>
        <Button
          ariaLabel="change email"
          variant="newTertiary"
          sx={{
            width: ['13.8rem', '19.8rem'],
            height: '4.8rem',
            borderRadius: '10rem',
          }}
          onClick={() => navigate('/dashboard/account')}
        >
          Change Email
        </Button>
      </Flex>
    </Overlay>
  )
}
