import React, { ReactElement, ReactNode } from 'react'
import { createPortal } from 'react-dom'

export default function CreatePortalWrapper({
  children,
  container,
  shouldCreatePortal = true,
}: {
  children: ReactNode
  container?: HTMLElement | null
  shouldCreatePortal?: boolean
}): ReactElement {
  const portalContainer =
    container || (typeof window !== 'undefined' ? window?.['gatsby-focus-wrapper'] : document.body)

  return !shouldCreatePortal ? (
    <>{children}</>
  ) : (
    <>{children && portalContainer ? createPortal(children, portalContainer) : null}</>
  )
}
