import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useSendMessageMutation } from 'store/api/barry/barry'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { sendMessage } from 'store/barry/barry'
import { Box, Flex, Input, Text, Image } from 'theme-ui'
import { Button, Svg } from 'ui'
import ChatSendIconWhite from 'images/barry/chat-send-icon-white.svg'
import ChatSendIcon from 'images/barry/chat-send-icon.svg'
import InboundChatBubble from './InboundChatBubble'
import OutboundChatBubble from './OutboundChatBubble'
import ChatBackgroundLight from 'images/barry/chat-background-light-mode.png'
import ChatBackgroundDark from 'images/barry/barry-chat-background-dark.png'
import TypingDotsAnimated from 'components/Barry/utils/TypingDotsAnimated'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import useGetColorMode from 'utils/useGetColorMode'

const maxMessageLength = 5000

function capitalize(text: string) {
  return text[0].toUpperCase() + text.slice(1)
}

export default function ChatWindow(): ReactElement {
  const [message, setMessage] = useState('')
  const isMessageEmpty = !message
  const { messages } = useAppSelector(x => x.barry.message)
  const forceEndConversation = useAppSelector(x => x.barry.userExperience.forceEndConversation)
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const scrollAreaRef = useRef<HTMLDivElement>(null)
  const [, { isLoading: isBarryTyping }] = useSendMessageMutation({
    fixedCacheKey: 'sendMessage',
  })
  const isMobile = useBreakpointIndex() === 0

  const dispatch = useAppDispatch()
  const { isLightMode } = useGetColorMode()

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    scrollAreaRef.current?.scrollTo({
      top: scrollAreaRef.current?.scrollHeight ?? 0,
      behavior: 'smooth',
    })
  }, [messages, isBarryTyping, isMobile])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        borderRadius: [0, '24px 24px 0 0'],
      }}
    >
      <Flex
        sx={{
          zIndex: 'zIndex0',
          position: 'absolute',
          top: '7.4rem',
          pointerEvents: 'none',
        }}
      >
        <Image
          alt="barry chat background"
          src={isLightMode ? ChatBackgroundLight : ChatBackgroundDark}
        />
      </Flex>
      <Flex
        data-testid="chat-bubble-display"
        className="hide-scrollbar-in-barry-chat"
        ref={scrollAreaRef}
        sx={{
          width: '100%',
          height: 0,
          flex: '1 1 auto',
          flexDirection: 'column',
          paddingTop: ['1.6rem', '1.6rem'],
          gap: '1.6rem',
          overflowY: 'auto',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {messages.map(message => {
            if (!message.fromBarry) {
              return (
                <OutboundChatBubble
                  text={message.content}
                  userName={capitalize(message.sender)}
                  time={message.time}
                  key={message.timestamp}
                  hasErrored={message.hasErrored ?? false}
                  timestamp={message.timestamp}
                  errorMessage={message.errorMessage}
                />
              )
            } else {
              return (
                <InboundChatBubble
                  text={message.content}
                  userName={capitalize(message.sender)}
                  time={message.time}
                  key={message.timestamp}
                  needsHuman={message.needsHuman}
                />
              )
            }
          })}
        </Box>
        <Flex sx={{ mb: '0.8rem', width: '100%', alignItems: 'flex-end' }}>
          <Flex
            sx={{
              pl: '1.6rem',
              fontSize: '1.2rem',
              visibility: isBarryTyping ? 'visible' : 'hidden',
              fontWeight: 500,
              justifyContent: 'flex-start',
              width: '100%',
            }}
            color="white50"
            data-testid="barry-is-typing"
          >
            <Text>Barry is &quot;typing&quot;</Text>
            <TypingDotsAnimated />
          </Flex>
          <Flex
            sx={{
              justifyContent: 'flex-end',
              pr: '1.6rem',
              visibility: message.length ? 'visible' : 'hidden',
            }}
            data-testid="message-char-count"
          >
            <Text
              variant="size12Weight700"
              sx={{
                color: 'white50',
              }}
            >
              {message.length}/{maxMessageLength}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex sx={{ flexDirection: 'column' }}>
        <Flex
          as="form"
          onSubmit={e => {
            e.preventDefault()
            if (message && !isBarryTyping) {
              setMessage('')
              dispatch(sendMessage({ messageToSend: message, sessionToken }))
            }
          }}
          sx={{
            width: '100%',
            display: forceEndConversation ? 'none' : 'flex',
            height: '5.6rem',
            borderTop: ({ colors }) => `2px solid ${colors?.borderFaint}`,
            backgroundColor: 'blackKnight',
            marginBottom: '-4.8rem',
            zIndex: 'zIndex2',
            alignItems: 'center',
            paddingLeft: '1.6rem',
            paddingRight: '1.6rem',
          }}
        >
          <Input
            data-testid="chat-input-field"
            placeholder="Type message here…"
            maxLength={maxMessageLength}
            value={message}
            onChange={({ target }) => setMessage(target.value)}
            sx={{
              fontSize: '1.5rem',
              color: 'aliceBlue',
              marginRight: '1.6rem',
              border: 'none',
              outline: 'none',
              padding: 0,
              '::placeholder': {
                color: 'aliceBlue40',
              },
            }}
          />
          <Button
            data-testid="chat-submit-button"
            type="submit"
            ariaLabel="submit chat message"
            variant="barryChatSubmit"
            disabled={isBarryTyping || isMessageEmpty}
            sx={{
              backgroundColor: isMessageEmpty ? 'darkJungleGreen' : 'none',
              backgroundImage: !isMessageEmpty
                ? 'linear-gradient(180deg, #7252E5 0%, #4A20E5 100%)'
                : 'none',
              boxShadow: !isMessageEmpty
                ? '0px 1px 0.2px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 10px 0px rgba(97, 63, 219, 0.50)'
                : 'none',
              '& svg path': {
                fill: 'white',
              },
              '&:not([disabled])': {
                '& svg path': {
                  fill: 'commonWhite',
                },
              },
              ':hover': {
                ':not([disabled])': {
                  backgroundImage: !isMessageEmpty
                    ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #7252E5 0%, #4A20E5 100%);'
                    : 'none',
                  '& svg path': {
                    fill: 'commonWhite',
                  },
                },
              },
            }}
          >
            {message === '' || isBarryTyping ? (
              <Svg svg={ChatSendIcon} />
            ) : (
              <Svg svg={ChatSendIconWhite} />
            )}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
