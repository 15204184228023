import React, { ReactNode } from 'react'
import { Flex } from 'theme-ui'

export default function Overlay({ children }: { children?: ReactNode }): React.ReactElement {
  return (
    <Flex
      sx={{
        pt: '10rem',
        transition: 'opacity 0.2s ease',
        backgroundColor: 'black',
        opacity: ['1', '0.8'],
        width: '100%',
        height: '100%',
        position: 'fixed',
        left: '0px',
        top: '0px',
        zIndex: 'zIndex600',
        justifyContent: 'center',
      }}
    >
      {children}
    </Flex>
  )
}
