import React, { ReactElement, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { sendMessage, Page, switchPage } from 'store/barry/barry'
import { Flex, Text, Image } from 'theme-ui'
import { Button, Input } from 'ui'
import BarryIconBackground from 'images/barry/barry-background-splash.png'
import useQueryString from 'utils/useQueryString'
import omit from 'lodash/omit'

const MIN_CHAR_LENGTH = 20
const MAX_MESSAGE_LENGTH = 5000

export default function DescribeYourIssue(): ReactElement {
  const [message, setMessage] = useState('')
  const dispatch = useAppDispatch()
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { qs, nav } = useQueryString()

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        '@media screen and (max-height: 450px)': {
          position: 'absolute',
          top: '10rem',
          pb: '2.4rem',
        },
      }}
    >
      <Flex
        sx={{
          zIndex: 'zIndex0',
          position: 'absolute',
          pointerEvents: 'none',
          top: 0,
          '@media screen and (max-height: 450px)': {
            maxHeight: '50rem',
            overflow: 'hidden',
            width: '100%',
          },
        }}
      >
        <Image alt="barry background" src={BarryIconBackground} />
      </Flex>
      <Flex
        sx={{
          justifyContent: 'center',
          mb: '4rem',
        }}
      >
        <Text
          sx={{
            wordBreak: 'break-word',
            height: '100%',
            textAlign: 'center',
            color: 'aliceBlue',
          }}
          variant="size21Weight700"
        >
          What can we help you with?
        </Text>
      </Flex>
      <Flex sx={{ mx: ['2.4rem', '1.6rem', '1.6rem'], flexDirection: 'column' }}>
        <Input
          textAreaVariant="textarea.barryIssueArea"
          className="hide-scrollbar-in-barry-chat"
          sx={{
            backgroundColor: 'smokyBlack',
            height: '14rem',
            fontFamily: 'body',
            resize: 'none',
            zIndex: 'zIndex2',
            fontSize: '1.5rem',
            color: 'aliceBlue',
            '::placeholder': {
              fontFamily: 'body',
              color: 'aliceBlue40',
            },
          }}
          backgroundColorOnFocus={'smokyBlack'}
          isTextarea={true}
          rows={5}
          placeholder="Describe your issue or ask any question related to Control D"
          onChange={({ target }) => setMessage(target.value)}
          data-testid="pre-chat-message-input"
          maxLength={MAX_MESSAGE_LENGTH}
        />
        <Text
          color="aliceBlue40"
          sx={{
            ml: 'auto',
            mt: '1.6rem',
            mr: '0.4rem',
            fontSize: '1.3rem',
            height: '1.8rem',
          }}
          data-testid="char-count"
        >
          {message.length <= MIN_CHAR_LENGTH
            ? `20 characters minimum`
            : `${MAX_MESSAGE_LENGTH - message.length} character${
                message.length == MAX_MESSAGE_LENGTH - 1 ? '' : 's'
              } remaining`}
        </Text>
      </Flex>
      <Flex sx={{ justifyContent: 'center', mt: '3.2rem' }}>
        <Button
          ariaLabel="start chatting"
          sx={{
            height: '4.4rem',
            py: 0,
            width: ['100%', '100%'],
            mx: '2.4rem',
            alignItems: 'center',
            ':disabled': {
              cursor: 'auto',
              background: 'darkJungleGreen',
              color: 'title20',
              borderRadius: '10rem',
              border: 'none',
              boxShadow: 'none',
            },
          }}
          variant="gradientButton"
          onClick={() => {
            dispatch(switchPage(Page.CHAT_PAGE))
            dispatch(sendMessage({ messageToSend: message, sessionToken }))
            if (qs.barry) {
              nav({ ...omit(qs, 'barry') })
            }
          }}
          disabled={message.length < 20}
          data-testid="start-chat-button"
        >
          <Text sx={{ fontSize: '1.7rem', color: 'commonWhite' }}>Start chatting</Text>
        </Button>
      </Flex>
    </Flex>
  )
}
