import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { CONTROLDText } from 'ui/CONTROLD'
import { Button, Svg } from 'ui'
import useStartSetupFlow from 'components/SetupGuide/useStartSetupFlow'
import SetupGuideDialog from './SetupGuideDialog'
import useQueryString from 'utils/useQueryString'
import { SetupGuideIntermediateStates } from 'components/SetupGuide/SetupGuide.interface'
import useCopySetupLink from 'utils/useCopySetupLink'
import InfoIconSmall from 'images/dashboard/info-icon-small.svg'

export default function ConfigurationPane(): ReactElement {
  const { auto: startAutoSetup, manual: startManualSetup } = useStartSetupFlow()
  const { qs, nav } = useQueryString()
  const { onCopySetupLinkClick } = useCopySetupLink(qs.deviceId)

  return (
    <SetupGuideDialog
      title={`Configure ${CONTROLDText}`}
      isLeftContentVisible
      footer={
        <Flex
          sx={{
            gap: '0.8rem',
          }}
        >
          <Svg fill="aliceBlue60" svg={InfoIconSmall} />
          <Text
            variant="size12Weight400Line138Letter048"
            sx={{ textAlign: 'center', color: 'aliceBlue60' }}
          >
            Want to share this configuration with someone else?&ensp;
            <Button
              data-testid="setup-guide-copy-link-button"
              ariaLabel="setup guide copy link button"
              variant="simple"
              sx={{
                display: 'inline-flex',
                textDecoration: 'underline',
                p: 0,
                color: 'aliceBlue60',
              }}
              onClick={onCopySetupLinkClick}
            >
              <Text
                sx={{
                  color: 'aliceBlue60',
                }}
                variant="size12Weight400Line138Letter048"
              >
                Copy Setup Link
              </Text>
            </Button>
          </Text>
        </Flex>
      }
      sxFooterContainer={{
        backgroundColor: 'transparent',
        justifyContent: 'center',
      }}
    >
      <Flex
        sx={{
          width: ['100%', '45.2rem'],
          height: '100%',
          flexDirection: 'column',
          py: '4rem',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          variant="size14Weight400Line138"
          sx={{
            maxWidth: '31.7rem',
            mb: '4rem',
            textAlign: 'center',
            alignSelf: 'center',
            color: 'aliceBlue',
          }}
        >
          Set up {CONTROLDText} manually or download an app to configure it with a single click
        </Text>
        <Flex
          sx={{
            height: 'fit-content',
            flexDirection: 'column',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              mr: 0,
              mb: '2.4rem',
            }}
          >
            <Button
              variant="newPrimary"
              ariaLabel="automatic setup"
              data-testid="automatic-setup-button"
              sx={{
                height: '3.8rem',
              }}
              onClick={startAutoSetup}
            >
              Automatic Setup
            </Button>
            <Text
              variant="size12Weight400Line138Letter048"
              sx={{ color: 'aliceBlue60', mt: '0.8rem' }}
            >
              Use the App
            </Text>
          </Flex>
          <Flex sx={{ flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
            <Button
              ariaLabel="manual setup"
              variant="newSecondary"
              data-testid="manual-setup-button"
              sx={{
                height: '3.8rem',
              }}
              onClick={startManualSetup}
            >
              Manual Setup
            </Button>
            <Text
              variant="size12Weight400Line138Letter048"
              sx={{ color: 'aliceBlue60', mt: '0.8rem' }}
            >
              Advanced
            </Text>
          </Flex>
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            mt: '2.4rem',
          }}
        >
          <Button
            data-testid="show-resolvers-button"
            ariaLabel="show endpoint resolvers"
            variant="simple"
            sx={{
              display: 'inline-flex',
              textDecoration: 'underline',
              p: 0,
              color: 'aliceBlue60',
            }}
            onClick={() => {
              nav({
                ...qs,
                helpPane: SetupGuideIntermediateStates.DNS,
                setupOs: qs.setupOs, // can be SetupOS or something new that has not been added to the SetupOs
                deviceId: qs.deviceId,
              })
            }}
          >
            <Text
              sx={{
                color: 'aliceBlue60',
              }}
              variant="size15Weight700"
            >
              See Resolvers
            </Text>
          </Button>
        </Flex>
      </Flex>
    </SetupGuideDialog>
  )
}
