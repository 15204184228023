import React, { ReactElement } from 'react'
import { ProfileDownloadButton } from '../index'
import { Flex, Text } from '@theme-ui/components'
import { Svg } from 'ui'
import DownloadIcon from 'images/helpPaneSteps/download-icon.svg'
import useMobileConfigUrl from 'components/SetupGuide/useMobileConfigUrl'

export enum BinaryVariant {
  INTEL_X64_EXE = 'controld_x86.exe',
  ARM_EXE = 'controld_arm.exe',
  INTEL_X64_AMD = 'controld_x86.dmg',
  ARM_AMD = 'controld_arm.dmg',
}

export default function BinaryVariantButtons({ isMac }: { isMac: boolean }): ReactElement {
  const urlX64 = useMobileConfigUrl({
    variant: isMac ? BinaryVariant.INTEL_X64_AMD : BinaryVariant.INTEL_X64_EXE,
  })
  const urlArm = useMobileConfigUrl({
    variant: isMac ? BinaryVariant.ARM_AMD : BinaryVariant.ARM_EXE,
  })

  const isX64 = ['x86_64', 'amd64', 'win64', 'intel'].some(item =>
    navigator.userAgent?.toLowerCase()?.includes(item),
  )
  // commenting out for now till we decide whether we want to keep architecture detection or not
  // const isArm = ['arm', 'arm64'].some(item => navigator.userAgent?.toLowerCase()?.includes(item))
  const isDetected = false //isX64 || isArm

  return (
    <Flex
      sx={{
        gap: isDetected ? '1.6rem' : '1.2rem',
        flexDirection: isDetected ? 'column' : 'row',
        alignItems: 'center',
      }}
    >
      <ProfileDownloadButton
        sx={{
          width: 'fit-content',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '0.8rem',
        }}
        url={urlX64}
      >
        <Text>{isDetected ? (isX64 ? 'Intel x64' : '64-bit Arm') : 'Intel x64'}</Text>
        <Svg svg={DownloadIcon} />
      </ProfileDownloadButton>
      {!isDetected && (
        <Text variant="size12Weight700Line138Letter048" sx={{ color: 'aliceBlue' }}>
          OR
        </Text>
      )}
      <ProfileDownloadButton
        sx={{
          ...(isDetected
            ? {
                p: 0,
                gap: '0.8rem',
                backgroundColor: 'transparent',
                border: 'none',
                width: 'auto',
                minHeight: 'auto',
                height: 'auto',
                span: {
                  color: 'purple',
                },
                ':hover': {
                  ':not([disabled])': {
                    background: 'transparent',
                    color: 'purple',
                  },
                },
              }
            : {
                width: '9rem',
              }),
          gap: '0.8rem',
        }}
        url={urlArm}
      >
        <Text
          sx={{
            textDecoration: isDetected ? 'underline' : 'unset',
          }}
        >
          {isDetected ? (isX64 ? '64-bit Arm' : 'Intel x64') : 'ARM'}
        </Text>
        <Svg svg={DownloadIcon} fill={isDetected ? 'purple' : 'commonWhite'} />
      </ProfileDownloadButton>
    </Flex>
  )
}
