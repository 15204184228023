import React, { ReactElement, useState } from 'react'
import { scrollingContainerRef } from 'components/Dashboard/Dashboard'
import { DeletionType } from 'ui/ConfirmDeletionTrayOrDialog/ConfirmDeletionContent'
import { getTimeSinceNow } from 'utils/getTimeSinceNow'
import { ConfirmDeletionTrayOrDialog } from 'ui/ConfirmDeletionTrayOrDialog'
import { useDeleteDeviceMutation } from 'store/api/devices'
import { endActivityLogSession } from 'store/activityLog'
import { setSelectedDevicePK } from 'store/persist'
import { useAppDispatch } from 'store/hooks'
import { Setter } from 'utils'

export default function DeleteDeviceConfirmation({
  dismiss,
  lastActivity,
  devicePk,
  deviceToDeletePk,
  setDeviceToDeletePk,
}: {
  dismiss: () => void
  lastActivity?: number
  devicePk?: string
  deviceToDeletePk?: string
  setDeviceToDeletePk: Setter<string>
}): ReactElement {
  const dispatch = useAppDispatch()
  const [deleteDevice, { isLoading: isDeletionDeviceLoading }] = useDeleteDeviceMutation()
  const [isRequestInFlight, setIsRequestInFlight] = useState(false)

  return (
    <ConfirmDeletionTrayOrDialog
      element={scrollingContainerRef.current}
      title="Delete Endpoint"
      onClose={dismiss}
      type={DeletionType.DEVICE}
      isRequestInFlight={isDeletionDeviceLoading || isRequestInFlight}
      timeSinceLastQuery={getTimeSinceNow(lastActivity)}
      notificationText="Deleting an active Endpoint will instantly break Internet on every machine that uses this resolver."
      checkboxText="I understand that deleting this endpoint is permanent and can’t be undone."
      onCancel={() => {
        setDeviceToDeletePk('')
        dismiss()
      }}
      onDelete={() => {
        setIsRequestInFlight(true)
        if (deviceToDeletePk === devicePk) {
          dispatch(endActivityLogSession())
          dispatch(setSelectedDevicePK(devicePk))
        }
        deleteDevice({
          pk: devicePk || '',
        })
          .unwrap()
          .then(() => {
            setDeviceToDeletePk('')
            dismiss()
          })
          .catch(() => {})
          .finally(() => setIsRequestInFlight(false))
      }}
    />
  )
}
