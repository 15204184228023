import React, { ReactElement, useState } from 'react'
import { Flex, Text } from '@theme-ui/components'
import { Option } from 'ui'
import { ProfileDownloadButton, GenerateQrCodeButton } from 'components/SetupGuide/Components'
import AdvancedSettings from 'components/SetupGuide/Components/AdvancedSettings'
import QrCode from 'components/SetupGuide/Components/QrCode'
import useMobileConfigUrl from 'components/SetupGuide/useMobileConfigUrl'
import type { Setter } from 'utils'
import DividerWithText from 'ui/DividerWithText'

export interface AdvancedSettingsType {
  isCommonExclude: boolean
  shouldTrustRootCa: boolean
  shouldPreventDisablement: boolean
  clientId: string
}

export default function ProfileDownload({
  isQrCode,
  setIsQrCode,
  text,
}: {
  isQrCode: boolean
  setIsQrCode: Setter<boolean>
  text?: string
}): ReactElement {
  const [excludedNetworks, setExcludedNetworks] = useState<Option[] | undefined>()
  const [excludedDomains, setExcludedDomains] = useState<Option[] | undefined>()
  const [isQrCodeLoading, setIsQrCodeLoading] = useState<boolean>(false)
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false)

  const [advancedSettings, setAdvancedSettings] = useState<AdvancedSettingsType>({
    isCommonExclude: true,
    shouldTrustRootCa: false,
    shouldPreventDisablement: false,
    clientId: '',
  })

  const url = useMobileConfigUrl({
    advancedSettings,
    excludedDomains,
    excludedNetworks,
    isQrCode,
  })

  return (
    <>
      <Flex
        sx={{
          width: ['100%', '45.2rem'],
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {isQrCode ? (
          <QrCode
            url={url}
            isQrCodeLoading={isQrCodeLoading}
            setIsQrCode={setIsQrCode}
            setIsQrCodeLoading={setIsQrCodeLoading}
            text={text}
          />
        ) : (
          <>
            <AdvancedSettings
              advancedSettings={advancedSettings}
              setAdvancedSettings={setAdvancedSettings}
              excludedNetworks={excludedNetworks}
              setExcludedNetworks={setExcludedNetworks}
              excludedDomains={excludedDomains}
              setExcludedDomains={setExcludedDomains}
              isOpen={isAdvancedSettingsOpen}
              setIsOpen={setIsAdvancedSettingsOpen}
            />
            {text && (
              <Text variant="size14Weight400Line138" sx={{ color: 'aliceBlue', my: '4rem' }}>
                {text}
              </Text>
            )}
            <Flex
              sx={{
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '1.6rem',
              }}
            >
              <ProfileDownloadButton url={url}>Download Profile</ProfileDownloadButton>
              <DividerWithText text="OR" />
              <GenerateQrCodeButton
                onClick={() => {
                  setIsQrCodeLoading(true)
                  setIsQrCode(true)
                }}
              >
                Scan QR Code
              </GenerateQrCodeButton>
            </Flex>
          </>
        )}
      </Flex>
    </>
  )
}
