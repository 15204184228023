import { useLocation } from '@reach/router'
import { useCallback } from 'react'
import { setQueryFilters, setSelectedPresetTimeRange as setForActivityLog } from 'store/activityLog'
import {
  setSelectedPresetTimeRange as setForAnalytics,
  setTimeRange as setStatisticsTimeRange,
} from 'store/analytics'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { PresetTimeRange } from 'ui/Analytics/PresetTimeRange'
import { setLogFilters, setSelectedPresetTimeRange as setForAdminLogs } from 'store/adminLog'

export default function useTimeRangeSelector(): {
  startTs: number
  endTs: number
  setTimeRange: (startTs: number, endTs: number) => void
  selectedTimeRangeOption: PresetTimeRange
  setSelectedTimeRangeOption: (presetTimeRange: PresetTimeRange) => void
  isStatistics: boolean
  isAdminLogs: boolean
} {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const isStatistics = location.pathname.endsWith('statistics')
  const isAdminLogs = location.pathname.endsWith('admin-logs')
  const { startTs, endTs } = useAppSelector(s =>
    isStatistics
      ? s.analytics.timeRange
      : isAdminLogs
      ? s.adminLog.logFilters
      : s.activityLog.queryFilters,
  )

  const setTimeRange = useCallback(
    (startTs: number, endTs: number) => {
      isStatistics
        ? dispatch(setStatisticsTimeRange({ startTs, endTs }))
        : isAdminLogs
        ? dispatch(setLogFilters({ startTs, endTs }))
        : dispatch(setQueryFilters({ startTs, endTs }))
    },
    [dispatch, isAdminLogs, isStatistics],
  )

  const setSelectedTimeRangeOption = useCallback(
    (presetTimeRange: PresetTimeRange) => {
      dispatch(
        isStatistics
          ? setForAnalytics(presetTimeRange)
          : isAdminLogs
          ? setForAdminLogs(presetTimeRange)
          : setForActivityLog(presetTimeRange),
      )
    },
    [dispatch, isAdminLogs, isStatistics],
  )
  const selectedTimeRangeOption = useAppSelector(s =>
    isStatistics
      ? s.analytics.selectedPresetTimeRange
      : isAdminLogs
      ? s.adminLog.selectedPresetTimeRange
      : s.activityLog.selectedPresetTimeRange,
  )
  return {
    startTs,
    endTs,
    setTimeRange,
    selectedTimeRangeOption,
    setSelectedTimeRangeOption,
    isStatistics,
    isAdminLogs,
  }
}
