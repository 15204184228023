import Tippy from '@tippyjs/react'
import React, { ReactElement } from 'react'
import { Placement } from 'tippy.js'
import useGetColorMode from 'utils/useGetColorMode'

export function TooltipWrapper({
  children,
  text,
  placement,
  offset,
  maxWidth,
  hideRef,
  disabled,
}: {
  children: ReactElement
  text: string | React.ReactNode
  placement?: Placement
  offset?: [number, number]
  maxWidth?: number
  hideRef?: React.MutableRefObject<(() => void) | undefined>
  disabled?: boolean
}): ReactElement {
  const { isLightMode } = useGetColorMode()

  return (
    <Tippy
      theme={isLightMode ? 'light-org-tooltip' : 'org-tooltip'}
      arrow={false}
      content={text}
      maxWidth={maxWidth || 200}
      offset={offset || [0, 4]}
      placement={placement}
      onCreate={instance => {
        if (hideRef) {
          hideRef.current = () => {
            instance.hide()
          }
        }
      }}
      disabled={disabled}
    >
      {children}
    </Tippy>
  )
}
