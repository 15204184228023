import React, { ReactElement, ReactNode, useRef } from 'react'
import { Spinner, Text, ThemeUIStyleObject } from 'theme-ui'
import Tippy from '@tippyjs/react'
import { Button, Svg, SvgProps } from 'ui'
import { Instance, Placement } from 'tippy.js'
import { useHideTooltipOnScroll } from 'utils/useHideTooltipOnScroll'
import ExternalSvgIcon, { ExternalIcon } from 'components/Dashboard/ExternalSvgIcon'
import useGetColorMode from 'utils/useGetColorMode'
import useBoundaryElement from 'utils/useBoundaryElement'

interface IconWithTippyPopupProps extends Omit<SvgProps, 'svg'> {
  content: string | ReactNode
  isEnabled?: boolean
  testId?: string
  maxWidth?: number
  onShow?: (instance: Instance) => void | false
  placement?: Placement
  tippyProps?: Partial<React.ComponentProps<typeof Tippy>>
  shouldStopPropagation?: boolean
  theme?: string
  svg?: React.ElementType
  externalIcon?: ExternalIcon
  ariaLabel?: string
  onClick?: () => void
  sxButton?: ThemeUIStyleObject
  isVisible?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  sxTooltipText?: ThemeUIStyleObject
  boundaryElementTestId?: string | undefined
}

export default function IconWithTippyPopup({
  content,
  sx,
  isEnabled = true,
  testId,
  svg,
  externalIcon,
  maxWidth,
  fill,
  descriptiveText,
  onShow,
  placement = 'auto',
  tippyProps = {},
  shouldStopPropagation,
  theme,
  ariaLabel,
  onClick,
  sxButton,
  isVisible,
  isLoading,
  isDisabled,
  sxTooltipText,
  boundaryElementTestId,
}: IconWithTippyPopupProps): ReactElement {
  const hideRef = useRef<() => void>()
  const { isLightMode } = useGetColorMode()
  const boundaryElement = useBoundaryElement(boundaryElementTestId || 'parent')

  useHideTooltipOnScroll(hideRef)

  return (
    <Tippy
      onShow={onShow}
      onCreate={instance => {
        hideRef.current = () => {
          instance.hide()
        }
      }}
      content={
        <Text sx={{ overflowWrap: 'break-word', color: 'aliceBlue', ...sxTooltipText }}>
          {content}
        </Text>
      }
      arrow={false}
      theme={theme || (isLightMode ? 'light-org-tooltip' : 'org-tooltip')}
      touch="hold"
      interactive={true}
      appendTo={boundaryElement}
      maxWidth={maxWidth}
      disabled={!isEnabled || isDisabled}
      placement={placement}
      visible={isVisible}
      {...tippyProps}
    >
      {/* Button is important for accessibility */}
      <Button
        disabled={!isEnabled || isDisabled}
        data-testid={testId}
        variant="transparent"
        ariaLabel={` ${ariaLabel ?? ''}`}
        sx={{
          p: 0,
          borderRadius: '50%',
          cursor: onClick ? 'pointer' : 'default',
          '&:hover': { '& svg': { opacity: 1 } },
          ...sxButton,
        }}
        onClick={onClick}
      >
        {isLoading ? (
          <Spinner color="aliceBlue" sx={{ width: '2.4rem', height: '2.4rem' }} />
        ) : (
          <>
            {externalIcon && (
              <ExternalSvgIcon
                icon={externalIcon.icon}
                type={externalIcon.type}
                fill={fill}
                sx={{
                  ...sx,
                }}
              />
            )}
            {svg && (
              <Svg
                svg={svg}
                fill={fill}
                descriptiveText={descriptiveText}
                sx={{
                  ...sx,
                  '@media (hover: none)': {
                    '&:hover': { opacity: 0.5 },
                  },
                }}
                onClick={shouldStopPropagation ? e => e.stopPropagation() : undefined}
              />
            )}
          </>
        )}
      </Button>
    </Tippy>
  )
}
