import React, { ReactElement, useCallback, useRef } from 'react'
import { IconButtonContent, SimpleActionMenuItemProps } from 'ui'
import EditIcon from 'images/edit-icon.svg'
import { Text } from 'theme-ui'
import MoreButton from 'ui/MoreButton'
import ResolversIcon from 'images/menus/profilesAndDevices/see-resolver-icon.svg'
import ConfigureIcon from 'images/menus/profilesAndDevices/configure-icon.svg'
import { useAppDispatch } from 'store/hooks'
import { setDeviceForEdit } from 'store/multiprofile'
import useQueryString from 'utils/useQueryString'
import { DeviceDialogType } from '../DeviceModalDialog'
import { SetupGuideIntermediateStates, SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import { autoConfigurablePlatforms } from 'components/SetupGuide/Panes/ShowUserDns'
import AnalyticsIcon from 'images/dashboard/devices/analytics.svg'
import { useDeviceClientsData } from 'components/Dashboard/Analytics/DeviceClients/useDeviceClientsData'

export default function DeviceItemOptionsMenu({
  devicePk,
  deviceIcon,
  isDevicePending,
}: {
  devicePk: string
  deviceIcon?: string
  isDevicePending: boolean
}): ReactElement {
  const dispatch = useAppDispatch()
  const hideRef = useRef<() => void>()
  const { nav, qs, relocate } = useQueryString()
  const { setSelectedDeviceId: setAnalyticsDeviceId } = useDeviceClientsData()

  const handleDeviceSetupClick = useCallback((): void => {
    const setupOs = deviceIcon as SetupOs

    if (autoConfigurablePlatforms.includes(setupOs)) {
      nav({
        ...qs,
        helpPane: SetupGuideIntermediateStates.Configure,
        setupOs: deviceIcon as SetupOs,
        deviceId: devicePk,
      })
    } else {
      nav({
        ...qs,
        helpPane: SetupGuideIntermediateStates.DNS,
        setupOs: setupOs,
        deviceId: devicePk,
      })
    }
  }, [qs, deviceIcon, nav, devicePk])

  const options: SimpleActionMenuItemProps[] = [
    isDevicePending
      ? {
          dataTestId: 'configure-device-button',
          ariaLabel: 'configure option',
          customOpacity: 1,
          children: (
            <IconButtonContent startIcon={ConfigureIcon} startIconFill="purple">
              <Text sx={{ color: 'purple' }}>Configure</Text>
            </IconButtonContent>
          ),
          onClick: handleDeviceSetupClick,
        }
      : {
          ariaLabel: 'resolvers option',
          children: <IconButtonContent startIcon={ResolversIcon}>Resolvers</IconButtonContent>,
          onClick: () => {
            nav({
              ...qs,
              helpPane: SetupGuideIntermediateStates.DNS,
              setupOs: deviceIcon as SetupOs,
              deviceId: devicePk,
            })
          },
        },
    {
      ariaLabel: 'edit option',
      children: <IconButtonContent startIcon={EditIcon}>Edit</IconButtonContent>,
      onClick: () => {
        dispatch(setDeviceForEdit(devicePk))
        nav({ ...qs, deviceDialog: DeviceDialogType.EDIT })
      },
    },
    {
      ariaLabel: 'analytics option',
      children: <IconButtonContent startIcon={AnalyticsIcon}>Statistics</IconButtonContent>,
      onClick: () => {
        if (devicePk) {
          setAnalyticsDeviceId(devicePk)
        }
        relocate('/dashboard/statistics')
      },
    },
  ]

  return (
    <MoreButton
      hideRef={hideRef}
      options={options}
      ariaLabel="device action options"
      dataTestId="device-action-options-button"
      appendToBoundaryElement="dashboard-devices"
      placement="bottom-end"
      sxDropdownContent={{
        width: '25.6rem',
      }}
    />
  )
}
