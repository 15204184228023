import AttachedIcon from 'images/barry/attach-icon.svg'
import React, { ReactElement, useState } from 'react'
import { usePreAuthTokenMutation, useSendSupportTicketMutation } from 'store/api/support/support'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Page, switchPage } from 'store/barry/barry'
import { Flex, Spinner, Text } from 'theme-ui'
import ChatBackground from 'images/barry/chat-background.svg'
import SupportTicketInput from './BarryChatComponents/SupportTicketInput'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import { Button, Svg } from 'ui'

export default function SubmitTicketView(): ReactElement {
  const dispatch = useAppDispatch()
  const { messages } = useAppSelector(x => x.barry.message)
  const { data: userData } = useGetUser()
  const [email, setEmail] = useState(userData?.email || '')
  const [comments, setComments] = useState('')
  const [
    sendSupportTicket,
    {
      isError: isSendSupportTicketError,
      isUninitialized: isSendSupportTicketUninitialized,
      isLoading: isSendSupportTicketLoading,
    },
  ] = useSendSupportTicketMutation()
  const [
    getPreAuthToken,
    { isError: isPreAuthError, isLoading: isPreAuthLoading, isSuccess: isPreAuthSuccess },
  ] = usePreAuthTokenMutation()

  const shouldDisplayErrorMessage = isSendSupportTicketError || isPreAuthError
  const isRequestLoading =
    isSendSupportTicketLoading ||
    isPreAuthLoading ||
    ((isPreAuthSuccess || isPreAuthError) && isSendSupportTicketUninitialized)

  return (
    <Flex
      className="hide-scrollbar"
      sx={{
        flexDirection: 'column',
        height: '100%',
        width: ' 100%',
      }}
    >
      <Flex
        data-testid="form-ticket-view"
        className="hide-scrollbar"
        sx={{
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          px: '1.6rem',
          position: 'relative',
          top: 0,
          '@media screen and (max-height: 530px)': {
            position: 'fixed',
            top: '7.2rem',
            overflowY: 'scroll',
            pb: '18rem',
          },
        }}
        as="form"
        onSubmit={e => {
          e.preventDefault()
          if (!email) {
            return
          }

          getPreAuthToken({ userEmail: email })
            .unwrap()
            .then(payload => {
              if (!!payload) {
                sendSupportTicket({
                  email: email,
                  subject: `Barry Support Ticket from: ${email}`,
                  chatlog: messages,
                  ...(comments && { message: comments }),
                  channel: 'chatbot',
                  token: payload.token,
                })
              }
            })
            .catch(() => {})
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
          }}
        >
          <Flex
            sx={{
              zIndex: 'zIndex-1',
              position: 'absolute',
              pointerEvents: 'none',
              width: '32rem',
              left: 0,
              overflow: 'hidden',
              '@media screen and (max-height: 450px)': {
                maxHeight: '50rem',
                overflow: 'hidden',
                width: '100%',
              },
            }}
          >
            <Svg svg={ChatBackground} />
          </Flex>

          <Flex
            sx={{
              width: '100%',
              flexDirection: 'row',
              backgroundColor: 'darkJungleGreen',
              minHeight: '4.8rem',
              border: ({ colors }) => `1px solid ${colors?.borderFaint}`,
              borderRadius: '1rem',
              pointerEvents: 'none',
              mt: ['2.4rem', '1.6rem'],
              justifyContent: 'center',
              alignItems: 'center',
              flesShrink: 0,
              gap: '1.0rem',
            }}
            data-testid="chatlog-attached-button"
          >
            <Svg svg={AttachedIcon} fill="white" />
            <Text variant="size14Weight700" sx={{ color: 'aliceBlue' }}>
              Chatlog Attached
            </Text>
          </Flex>
          <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
            <Text
              variant="size28Weight600"
              sx={{
                color: 'aliceBlue',
                mt: ['3.2rem', '2.4rem'],
                mb: 0,
              }}
            >
              Talk to a human
            </Text>
            <Text
              sx={{
                color: shouldDisplayErrorMessage ? 'orangeRed' : 'lightSteelBlue',
                mt: '0.8rem',
                mb: 0,
                maxWidth: '29.6rem',
                fontSize: '1.5rem',
                fontWeight: 'normal',
                textAlign: 'center',
              }}
            >
              {shouldDisplayErrorMessage
                ? 'Something went wrong, please try sending it again.'
                : "Fill this form out and we'll get back to you as soon as possible by email"}
            </Text>
          </Flex>
          <Flex
            sx={{ mt: ['2.4rem', '1.6rem'], width: '100%', gap: '2rem', flexDirection: 'column' }}
          >
            {' '}
            {/**
             * TODO: Using autofill for the email on chrome at least fills in string with a fontSize of 17px
             */}
            <SupportTicketInput
              label="Email"
              inputTestId="ticket_flow_email"
              onChange={({ target }) => setEmail(target.value)}
              value={email}
              name="email"
              required={true}
              type="email"
            />
            <SupportTicketInput
              label="Comments (optional)"
              required={false}
              inputTestId="ticket_flow_comments"
              onChange={({ target }) => setComments(target.value)}
              value={comments}
              name="comments"
              type="text"
            />
          </Flex>
        </Flex>
        <Flex
          sx={{
            display: ['none', 'flex'],
            height: '1px',
            width: '36rem',
            position: 'absolute',
            bottom: '7.3rem',
            left: 0,
            backgroundColor: 'blueYonder50',
          }}
        ></Flex>
        <Flex
          data-testid="submit-ticket"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'smokyBlack',
            height: '7.3rem',
            borderTop: ['1px solid', 'none'],
            borderColor: 'blueYonder50',
            mt: ['2rem', 'auto'],
            width: ['100%', '100%'],
            px: '1.6rem',
            position: ['fixed', 'relative'],
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Button
            ariaLabel="go back"
            variant="transparent"
            sx={{
              fontWeight: 'bold',
              color: 'lightSteelBlue',
              minWidth: '50%',
              fontSize: '1.8rem',
            }}
            onClick={() => dispatch(switchPage(Page.CHAT_PAGE))}
          >
            Back
          </Button>
          <Button
            ariaLabel="submit ticket"
            variant="gradientButton"
            color="title"
            sx={{
              color: 'white',
              minWidth: '50%',
              width: ['fit-content', 'fit-content'],
              height: '3.9rem',
              py: 0,
              zIndex: 'zIndex2',
              '& svg': {
                mt: '0.4rem',
              },
            }}
            type="submit"
            data-testid="ticket_flow_submit"
            disabled={isRequestLoading}
          >
            {isRequestLoading ? (
              <Spinner size={24} duration={900} color="aliceBlue" />
            ) : (
              <Text
                variant="size17Weight700"
                sx={{
                  pb: '0.2rem',
                }}
              >
                Send
              </Text>
            )}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
